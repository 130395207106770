import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/cheatSheet',
    name: 'cheatSheet',
    component: () => import('@/views/fortyTone/cheatSheet.vue'),
    meta: {
      title: '速查表',
    },
  },
  {
    path: '/vowelConsonant',
    name: 'vowelConsonant',
    component: () => import('@/views/fortyTone/vowelConsonant.vue'),
    meta: {
      title: '元/辅音表',
    },
  },
  {
    path: '/teacherStyle',
    name: 'teacherStyle',
    component: () => import('@/views/teacher/teacherStyle.vue'),
    meta: {
      title: '老师风采',
    },
  },
  {
    path: '/free',
    name: 'free',
    component: () => import('@/views/teacher/free.vue'),
    meta: {
      title: '免费领取资料包',
    },
  },
  {
    path: '/classOne',
    name: 'classOne',
    component: () => import('@/views/teacher/classOne.vue'),
    meta: {
      title: '韩语入门第一课',
    },
  },
  {
    path: '/learn',
    name: 'learn',
    component: () => import('@/views/teacher/learn.vue'),
    meta: {
      title: '四十音学习',
    },
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/video/video.vue'),
    meta: {
      title: '视频',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy'),
    meta: {
      title: '用户隐私',
    },
  },
  {
    path: '/thirdPrivacy',
    name: 'thirdPrivacy',
    component: () => import('@/views/thirdPrivacy'),
    meta: {
      title: '第三方',
    },
  },
  {
    path: '/interest',
    name: 'interest',
    component: () => import('@/views/interest'),
    meta: {
      title: '兴趣课程',
    },
  },
  {
    path: '/coach',
    name: 'coach',
    component: () => import('@/views/coach'),
    meta: {
      title: '一对一辅导',
    },
  },
  {
    path: '/download-app',
    name: 'download-app',
    component: () => import('@/views/download-app/download-app'),
    meta: {
      title: '洋葱韩语APP',
    },
  },
  {
    path: '/showoff',
    name: 'showoff',
    component: () => import('@/views/showoff'),
    meta: {
      title: '炫耀一下',
    },
  },
  {
    path: '/dubShow',
    name: 'dubShow',
    component: () => import('@/views/dubShow'),
    meta: {
      title: '配音秀',
    },
  },
  {
    path: '/curriculumFree',
    name: 'curriculumFree',
    component: () => import('@/views/curriculumFree'),
    meta: {
      title: '0基础入门课',
    },
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    component: () => import('@/views/curriculum'),
    meta: {
      title: '高级进阶发音课',
    },
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('@/views/work'),
    meta: {
      title: '作业',
    },
  },
  {
    path: '/dkDownload-app',
    name: 'dkDownload-app',
    component: () => import('@/views/dkDownload-app'),
    meta: {
      title: '下载',
    },
  },{
    path:'/OnionSharing',
    name:'OnionSharing',
    component: () => import('@/views/OnionSharing'),
    mate :{
      title :'情景对话'
    }
  },
  {
    path:'/IELTSDownload',
    name:'IELTSDownload',
    component: () => import('@/views/IELTSDownload'),
    mate :{
      title :'雅思APP'
    }
  },
 

]

const createRouter = () =>
  new VueRouter({
    // mode: 'history',
    base: '/',
    scrollBehavior: () => ({ y: 0 }),
    routes,
  })

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const router = createRouter()
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
