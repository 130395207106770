import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/_reset.scss'
import '@/styles/global.scss'

import 'amfe-flexible/index'
Vue.config.productionTip = false
import { Toast ,Icon } from 'vant'
Vue.use(Toast)
Vue.use(Icon)
import Vconsole from 'vconsole'

if (process.env.VUE_APP_MODE == 'dev') {
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}

function ajax(calback) {
  if(process.env.NODE_ENV !== 'production') {
    return
  }
  const url = 'https://jixedu.oss-cn-hangzhou.aliyuncs.com/data/%E5%9F%9F%E5%90%8D.json'
  const methods = 'get'
  const async = false
  // 1. 创建XMLHttpRequest对象。
  var xhr = null;
  if (window.XMLHttpRequest) {
    // 兼容 IE7+, Firefox, Chrome, Opera, Safari
    xhr = new XMLHttpRequest();
  } else {// 兼容 IE6, IE5
    xhr = new ActiveXObject("Microsoft.XMLHTTP")
  }
  // 2. 设置请求方式和参数。
  xhr.open(methods, url, async)
  // xhr.responseType = 'json'
  // 4. 响应处理。
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 200) {
      // 处理结果
      calback(JSON.parse(`${xhr.response}`))
    }
  }
  // 3.发送请求
  xhr.send()
}
ajax(function calback(res) {
  if(res) {
    window._requestDomain = res.onoin
  }
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
