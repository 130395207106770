import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    platform: '',
  },
  mutations: {
    SET_PLATFORM: (state, platform) => {
      state.platform = platform
    },
  },
  actions: {},
  modules: {},
  getters: {
    platform: (state) => state.user.platform,
  },
})
